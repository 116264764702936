//import React, { memo } from "react";
import React, { useState, memo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Annotation
} from "react-simple-maps";
//import { Popup } from 'semantic-ui-react'
const indexOf = [].indexOf;


const geoUrl = 'risikogebiete.json?'+encodeURI(process.env.REACT_APP_LAST_RKI_UPDATE); //"https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json"; //"https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const noInfo = ['Westsahara', 'Korea, Nord', 'Somaliland']

const riskText = (name, risk) => {
	if (name == 'Deutschland') {
		return "";
	}
	if (indexOf.call(noInfo, name) >= 0) {
		return "- keine verlässlichen Information"
	}
	if (!risk) {
		return " - aktuell kein Risikogebiet";
	}
	if (risk['partial']) {
		if (risk['exceptions']) {
			return ' - Risikogebiet ' + risk['exceptions'];
		}
		//item['regions']
		if (risk['regions']) {
			var regiontxts = []
			//regions": [{"name": "Gesamt Kontinentalfrankreich", "since": " 17. Oktober"}, {"name": "\u00dcb
			for (let region of risk['regions']) {
				//console.log('region', region)
                if (region['since']) {
                    regiontxts.push(region['name'] + ' (seit ' + region['since'] + ')')
                }
                else {
                    regiontxts.push(region['name'])
                }

			}
			return ' - Risikogebiete: ' + regiontxts.join(', '); //risk['regions'].join(', ');
		}
		//console.log(risk)
		return " - teilweises Risikogebiet"
	}

	if (risk['higherRisk']) {
		if (risk['variant']) {
			return " - Virusvariantengebiet"
		}
		else{
			return " - Hochrisikogebiet"
		}
	}

	return " - Risikogebiet seit dem " + risk['since']
};

const riskColor = (name, risk, hover) => {
	// hack - all hovers
	if (hover) {
		return '#0A3C4F';
	}
	if (name == 'Deutschland') {
		return '#888888';
	}
	if (indexOf.call(noInfo, name) >= 0) {
		return "#575757"
	}
	if (!risk) {
		//return "#1A793D"; 065C26
		//return '#065C26' if hover else "#1A793D";
		return hover ? '#065C26' : "#1A793D";
	}
	if (risk['partial']) {
		//return "#A76825" 7E4508
		//return '#7E4508' if hover else "#A76825"
		return hover ? '#7E4508' : "#A76825";
	}
	//return '#7E1C08' if hover else "#A73B24"
	if (risk['higherRisk']) {
		return hover ? '#661501' : "#7E1C08";
	}
	return hover ? '#7E1C08' : "#A73B24";

};

const MapChart = ({ setTooltipContent }) => {
  const [position, setPosition] = useState({ coordinates: [0, 25], zoom: 2 });

  function handleZoomIn() {
	if (position.zoom >= 4) return;
	setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
	if (position.zoom <= 1) return;
	setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
	setPosition(position);
  }


  return (
	<div>
	  <ComposableMap data-tip="" className='map' projectionConfig={{ scale: 200 }}>
		<ZoomableGroup
		  zoom={position.zoom}
		  center={position.coordinates}
		  onMoveEnd={handleMoveEnd}
		  //onMoveStart={handleMoveStart}
		>
		  <Geographies
		  geography={geoUrl}
		  //key={geo.rsmKey}
		  >
		 {({ geographies }) =>
			  geographies.map(geo => (
				<Geography
				  key={geo.rsmKey}
				  geography={geo}
				  onClick={() => {
					const { name, risk } = geo.properties;
					setTooltipContent(`${name}${riskText(name, risk)}`, 'haha');
				  }}
				  onMouseEnter={() => {
					const { name, risk } = geo.properties;
					setTooltipContent(`${name}${riskText(name, risk)}`, 'haha');
				  }}
				  onMouseLeave={() => {
					setTooltipContent("");
				  }}
				  style={{
					default: {
					  fill: (riskColor(geo.properties.name, geo.properties.risk)),
					  outline: "none"
					},
					hover: {
					  fill: (riskColor(geo.properties.name, geo.properties.risk, true)),
					  outline: "none"
					},
					pressed: {
					  fill: "#E42",
					  outline: "none"
					}
				  }}
				/>
			  ))
			}
		  </Geographies>
		  <Annotation
		 subject={[-15.0897, 28.7772]}
		 dx={-20}
		 dy={-10}
		 curve={1}
		 connectorProps={{
		   stroke: "#083C4F",
		   strokeWidth: 1,
		   strokeLinecap: "round"
		 }}
	   >
		 <text x="-5" fontSize='8' textAnchor="end" alignmentBaseline="middle" fill="#083C4F">
		   {"Kanaren wieder Rot"}
		 </text>
	   </Annotation>
	   {/* A JSX comment

		  <Annotation
		 subject={[2.3522, 48.8566]}
		 dx={-20}
		 dy={-10}
		 curve={1}
		 connectorProps={{
		   stroke: "#083C4F",
		   strokeWidth: 1,
		   strokeLinecap: "round"
		 }}
	   >
		 <text x="-5" font-size='12' textAnchor="end" alignmentBaseline="middle" fill="#083C4F">
		   {"Paris"}
		 </text>
	   </Annotation>
	   */}
		</ZoomableGroup>

	  </ComposableMap>
	  <div className="controls">
		<button onClick={handleZoomIn}>
		  <svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth="3"
		  >
			<line x1="12" y1="5" x2="12" y2="19" />
			<line x1="5" y1="12" x2="19" y2="12" />
		  </svg>
		</button>
		<button onClick={handleZoomOut}>
		  <svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth="3"
		  >
			<line x1="5" y1="12" x2="19" y2="12" />
		  </svg>
		</button>
	  </div>
	</div>
  );
};

export default MapChart;
