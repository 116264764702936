import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";

import "./styles.css";

import MapChart from "./MapChart";
/*
function Headline(props) {
  return (<div className="headline">
	<h1>{props.title}</h1>{props.byline}
	</div>
	);
}
function Footer(props) {
  return (<div className="footer">
	{props.title} - {props.byline}
	</div>
	);
}
*/
function App() {
  const [content, setContent] = useState("");
  //const lastUpdated2 = lastUpdated;
  //<Headline title="Covid Risikogebiete"/>
  //<div className="footer">Coivd Risikogebiete Karte auf Basis der RKI Daten ({lastUpdatedVars['rkiLastUpdated']}). Ohne Gewähr - Bitte immer auch die <a href='https://www.rki.de'>RKI</a> und <a href='https://https://www.auswaertiges-amt.de/de/ReiseUndSicherheit/10.2.8Reisewarnungen'>die Seite des Auswärtigen Amtes</a> zur Sicherheit überprüfen.</div>

  return (
	<div>
	  <MapChart setTooltipContent={setContent} />
	  <ReactTooltip>{content}</ReactTooltip>
	</div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
